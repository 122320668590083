import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import {fromLonLat} from 'ol/proj.js';
import {Feature} from "ol";
import {Point} from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Overlay from 'ol/Overlay.js';
import {Circle as CircleStyle, Fill, Stroke, Style, Text} from 'ol/style.js';
import {Control, defaults as defaultControls} from 'ol/control.js';

// ----- General variables
let lastUpdateTime = new Date()
let showBikes = true;
let hideUnavailableStations = false;
let layer = new VectorLayer({})

// ----- Custom control block
class ControlBoard extends Control {
    constructor(opt_options) {
        const options = opt_options || {};

        const panel = document.createElement('div');
        panel.className = 'custom-panel ol-unselectable ol-control';

        const lastUpdate = document.createElement('h5');
        lastUpdate.innerHTML = 'Dernière mise à jour à ' + (lastUpdateTime.getHours() + ':' + lastUpdateTime.getMinutes());
        panel.appendChild(lastUpdate)

        const visualizedData = document.createElement("span");
        visualizedData.innerHTML = '<span style="font-size: 13px">Affichage du nombre de vélos</span>'
        panel.appendChild(visualizedData)

        const button = document.createElement('button');
        button.innerHTML = 'Voir les places libres par station';
        panel.appendChild(button)

        const radio = document.createElement('input');
        radio.setAttribute('type', 'checkbox');
        radio.setAttribute("id", "hideUnavailableStations")
        panel.appendChild(radio)

        const radioLabel = document.createElement("label");
        radioLabel.setAttribute("for", "hideUnavailableStations")
        radioLabel.innerHTML = '<span style="font-size: 13px; padding-left: 5px"> Masquer les stations indisponibles</span>'
        panel.appendChild(radioLabel)

        super({
            element: panel,
            target: options.target,
        });

        radio.addEventListener('change', event => {
            hideUnavailableStations = !hideUnavailableStations
            updateData();
        })

        button.addEventListener('click', event => {
            showBikes = !showBikes;
            updateData();
            if (showBikes) {
                button.innerHTML = 'Voir les places libres par station';
                visualizedData.innerHTML = '<span style="font-size: 13px">Affichage du nombre de vélos</span>'
            } else {
                button.innerHTML = 'Voir les vélos par station';
                visualizedData.innerHTML = '<span style="font-size: 13px">Affichage du nombre de places libres</span>'
            }
        }, false);

        window.setInterval(() => {
            lastUpdate.innerHTML = 'Dernière mise à jour à ' + (lastUpdateTime.getHours() + ':' + lastUpdateTime.getMinutes());
        }, 5000)
    }
}

// ----- Map initialization
const map = new Map({
    controls: defaultControls().extend([new ControlBoard()]),
    target: 'map',
    layers: [
        new TileLayer({
            source: new OSM(
                {
                    attributions: 'Fond de carte par <a href="https://www.openstreetmap.org/copyright" target="_blank">les contributeurs d\'OpenStreetMap</a>. Données fournies par <a href="https://opendata.clermontmetropole.eu/" target="_blank">Clermont Métropole</a>. Développé par <a href="https://twitter.com/Artheriom" target="_blank">Florian Forestier</a>.'
                }
            )
        })
    ],
    view: new View({
        center: fromLonLat([3.082700, 45.785]),
        zoom: 14
    })
});

// ----- Theming
const styles = {
    station: new Style({
        image: new CircleStyle({
            radius: 18,
            fill: new Fill({color: '#D12230'}),
            stroke: new Stroke({
                color: '#FC838D',
                width: 2,
            }),
        }),
        text: new Text({
            font: '16px Calibri,sans-serif',
            fill: new Fill({
                color: '#fff',
            }),
            padding: [3, 3, 3, 3],
            backgroundFill: new Fill({color: '#D12230'})
        }),
    }),
    disabledStation: new Style({
        image: new CircleStyle({
            radius: 18,
            fill: new Fill({color: '#333'}),
            stroke: new Stroke({
                color: 'white',
                width: 2,
            }),
        }),
        text: new Text({
            font: '0px Calibri,sans-serif'
        })
    })
}

// ----- Add popup overlay
const popup = new Overlay({element: document.getElementById('popup')});
map.addOverlay(popup);

// ----- Update data
let url = 'https://opendata.clermontmetropole.eu/api/records/1.0/search/?dataset=cvelo_station_status&rows=100'
let inServiceState = 'IN_SERVICE'
updateData()
window.setInterval(updateData, 60000)

function updateData() {
    fetch(url).then(response => {
        response.json().then(res => {
            let features = [];
            res.records.forEach(e => {
                if (hideUnavailableStations && e.fields.status !== inServiceState) {
                    return;
                }

                let p = new Point(fromLonLat([e.fields.lon, e.fields.lat]));
                let f = new Feature({
                    type: e.fields.status === inServiceState ? 'station' : 'disabledStation',
                    geometry: p,
                    payload: e,
                })
                features.push(f)
            })
            map.removeLayer(layer)
            layer = new VectorLayer({
                source: new VectorSource({features: features}),
                style: feature => {
                    let s = styles[feature.get('type')]
                    s.getText().setText(showBikes ? feature.values_.payload.fields.num_bikes_available.toString() : feature.values_.payload.fields.num_docks_available.toString())
                    return s;
                },
            })

            map.addLayer(layer)

        })
    })
    lastUpdateTime = new Date()
}

// ----- Build popover information on click
map.on('singleclick', e => {
    let items = map.getFeaturesAtPixel(e.pixel)
    const element = popup.getElement();

    let popover = bootstrap.Popover.getInstance(element);
    if (popover) {
        popover.dispose();
    }

    if (items.length !== 0) {
        popup.setPosition(e.coordinate);

        let content = ""
        items.forEach((e, i) => {
            if (items.length !== 1) {
                content += "<h3>Station " + e.values_.payload.fields.name + "</h3>"
            }
            if (e.values_.payload.fields.status !== 'IN_SERVICE') {
                content += "<span class='unavailable'>Station indisponible</span><br />"
            }
            content += "<b>Vélos disponibles : </b>" + e.values_.payload.fields.num_bikes_available + "<br />" +
                "<b>Vélos indisponibles : </b>" + e.values_.payload.fields.num_bikes_disabled + "<br />" +
                "<b>Places disponibles : </b>" + e.values_.payload.fields.num_docks_available + "<br />" +
                "<b>Places indisponibles : </b>" + e.values_.payload.fields.num_docks_disabled + "<br />"

            if (items.length !== 1 && i + 1 !== items.length) {
                content += "<hr/>"
            }
        })


        popover = new bootstrap.Popover(element, {
            animation: false,
            container: element,
            content: content,
            html: true,
            placement: 'top',
            title: items.length !== 1 ? 'Stations multiples' : 'Station ' + items[0].values_.payload.fields.name,
        });
        popover.show();
    }


})